<template>
  <div>
    <el-button type="success" size="mini" @click="batchDistribute" v-if="isShow != 'show'" v-waves>批量发货</el-button>
    <el-button type="primary" size="mini" @click="editLogisticsNumber()" v-if="isShow != 'show'" icon="el-icon-edit" v-waves>修改物流单号</el-button>
    <span v-if="isShow != 'show'" style="margin-left:10px;color:red; font-size: 12px;">* 已收货或拒收的标签不会修改物流单号</span>
    <!-- <el-divider content-position="left">分发单详情</el-divider><br> -->
    <el-table ref="distributeTable"  :data="labelDetails"  :max-height="tableHeight" style="width: 100% ;margin-top:10px" border id="labelTable">
      <el-table-column type="index" width="50" label="序号" align="center"/>
      <el-table-column width="80" label="表单拆分" align="center">
          <template #default="scope">
            <el-tag type="success">{{scope.row.split}}</el-tag>
          </template>
      </el-table-column>
      <el-table-column prop="order.orderBatch" label="订单批次" width="100" align="center"/>
      <el-table-column prop="labelApply.creatorName" label="申请人" width="100" align="center"/>
      <el-table-column width="80px" label="标签状态" align="center">
          <template #default="scope">
            <el-tag :type="showLabelStateFilter(scope.row)">{{scope.row.labelState?scope.row.labelState.type:''}}</el-tag>
          </template>
      </el-table-column>
      <el-table-column width="80px" label="接收状态" align="center">
          <template #default="scope">
            <el-tag :type="showFilter(scope.row)">{{scope.row.acceptState?scope.row.acceptState.type:'未分发'}}</el-tag>
          </template>
      </el-table-column>
      <el-table-column prop="distributeTime" label="分发时间" min-width="100" align="center"/>
      <el-table-column prop="labelCount" label="防伪码标签（套）" width="70" align="center"/>
      <el-table-column prop="barcodeNumber" label="条形码标签（枚）" width="70" align="center">
        <template #default="scope">
            <span>{{scope.row.barcodeNumber?scope.row.barcodeNumber:0}}</span>
        </template>
      </el-table-column>
      <el-table-column width="70px" label="接收人" property="consignee" align="center">
          <template #default="scope">
            <span>{{scope.row.consignee?scope.row.consignee:''}}</span>
          </template>
      </el-table-column>
      <el-table-column prop="manufacturer.name" label="制造商" min-width="150" align="center"/>
      <el-table-column prop="manufacturer.code" label="制造商代码" width="70" align="center"/>
      <el-table-column prop="acceptTime" label="接收时间" min-width="100" align="center"/>
      <el-table-column prop="logisticsNumber" label="物流单号" width="130" align="center"/>
      <el-table-column prop="distributeRemark" label="分发备注" min-width="150" align="center"/>
      <!-- <el-table-column align="center" label="操作" width="150px" class-name="fixed-width" v-if="isShow != 'show'">
          <template  #default="scope">
            <el-button v-if="scope.row.acceptState && scope.row.acceptState.name!='FINISH_ACCEPT'&& scope.row.labelState.name==='IN_EFFECT'" type="success" size="mini" @click="handleDistribute(scope.row)" v-waves>修改物流单号</el-button>
            <el-button v-if="(!scope.row.logisticsNumber) && scope.row.labelState.name==='IN_EFFECT'" type="success" size="mini" @click="handleDistribute(scope.row)" v-waves>发货</el-button>
          </template>
      </el-table-column> -->
    </el-table>
    <el-dialog v-model="distributeShow" :title="edit?'修改物流单号':'发货单'" :close-on-click-modal="false"  @close="handleClose">
        <el-form ref="distribution" :model="distribution" label-width="100px">
          <el-form-item label="制造厂商:" prop="manufacturerId" :rules="[{required: true, message: '制造商不能为空',trigger:'blur'}]">
            <el-select clearable class="filter-item" placeholder="请选择制造厂商" v-model="distribution.manufacturerId">
              <el-option v-for="item in manufacturers" :key="item.id" :label="item.name" :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="'物流单号'+(index + 1) + ':'" :prop="'numbers.' + index + '.value'" v-for="(item,index) in distribution.numbers" :key="index" 
            :rules="[{required: true, message: '物流单号不能为空',trigger:'blur'},{pattern: /^[0-9A-Za-z]+$/, message: '物流单号只能是数字或字母'}]">
              <el-input style="width: 300px;"  v-model.trim="item.value" maxlength="50" show-word-limit></el-input>
              <el-button style="margin-left: 10px;" v-if="index===distribution.numbers.length-1" size="mini" type="success" @click="onClickRowAddButton()">新增</el-button>
              <el-button style="margin-left: 10px;" v-if="distribution.numbers.length>1" size="mini" type="danger" @click="onClickRowDeleteButton(item)">删除</el-button>
          </el-form-item>
          <el-form-item label="分发备注:" prop="distributeRemark">
              <el-input type="textarea" rows="2"  maxlength="100" show-word-limit v-model="distribution.distributeRemark"></el-input>
          </el-form-item>
          <el-form-item>
              <el-button size="mini" @click="onCancelButtonClick">取消</el-button>
              <el-button size="mini" type="primary" @click="onSubmitButtonClick(false)" v-loading.fullscreen.lock="fullscreenLoading" >确定</el-button>
          </el-form-item>
        </el-form>
    </el-dialog>
  </div>
</template>

<script>
import inDistributeInfoApi from '@/apis/distribute/inDistributeInfo'
import { mapGetters } from 'vuex'
export default {
  props: {
    id: {
      type: String
    },
    isShow:{
      type: String
    }
  },
  data() {
    return {
      edit:false,
      fullscreenLoading: false,
      orderId: this.id,
      labelDetails: [],
      manufacturers: [],
      distributeShow: false,
      distribution:{
        loading: false,
        distributeRemark: null,
        manufacturerId:null,
        orderId: null,
        logisticsNumber: null,
        numbers:[
          {value:''}
        ],
      },
    }
  },
  computed: {
    ...mapGetters(['mainContainerHeight']),
    tableHeight: function () {
      return this.mainContainerHeight - 40 -40
    }
  },
  created() {
    this.distribution.orderId = this.id
    this.getInDistributeInfo()
  },
  mounted(){
  },
  watch:{
    'distribution.manufacturerId':{
        handler: function(newV, oldV){
          if(newV){
            this.distribution.numbers = this.dealNumbers(newV)
            if(this.distribution.numbers.length === 0){
              // this.$message.warning("未发货的订单不能修改物流单号")
            }
            this.distribution.distributeRemark = this.labelDetails.filter(l => l.manufacturer.id === newV)[0].distributeRemark
          }
        }
      },
  },
  methods:{
    dealNumbers(newV){
       const arr = Array.from(new Set(this.labelDetails.filter(l => l.manufacturer.id === newV)))
       let result = []
       arr.forEach(a => {
        if(a.logisticsNumber){
          result.push(...a.logisticsNumber.split(','))
        }
       })
       let resultArrObj = []
       Array.from(new Set(result)).forEach(i => resultArrObj.push({value: i}))
       if(resultArrObj.length === 0){
         resultArrObj.push({value:''})
       }
       return resultArrObj
    },
    showLabelStateFilter(row){
      if(row.labelState && row.labelState.name === 'IN_EFFECT'){
        return 'success'
      }else{
        return 'danger'
      }
    },
    showFilter (row) {
      if(row.acceptState && row.acceptState.name==='FINISH_ACCEPT'){
        return 'success'
      }else if(row.acceptState && row.acceptState.name==='REJECT_ACCEPT'){
        return 'danger'
      }else if(row.acceptState && row.acceptState.name==='IN_ACCEPT'){
        return 'warning'
      }else {
        return ''
      }
    },
    onClickRowAddButton(){
      if(this.distribution.numbers && this.distribution.numbers.length >= 5 ){
        this.$message.warning('最多填写5个物流单号')
      }else{
        this.distribution.numbers.push({
        value:''
      })
      }
      
    },
    onClickRowDeleteButton(item){
      var index = this.distribution.numbers.indexOf(item)
      if(index !== -1){
        this.distribution.numbers.splice(index,1)
      }
    },
    batchDistribute(){
      this.manufacturers = this.unique(this.labelDetails.map(item => item.manufacturer))
      this.manufacturers.forEach(manufacturer => {
        const manufacturerLabels = this.labelDetails.filter(label => label.manufacturer.id === manufacturer.id)
        const tempArr = manufacturerLabels.filter(label => !!label.distributeTime)
        if(tempArr.length !== manufacturerLabels.length){
          // 设置厂商分发状态为分发中
          manufacturer.distributeState = "IN_DISTRIBUTE"
        }
      })
      // 分发中的厂商才能选择发货
      this.manufacturers = this.manufacturers.filter(m => m.distributeState === "IN_DISTRIBUTE" )
      if(this.manufacturers.length === 0){
        this.$message.warning('该订单已完成分发')
      }else{
        this.distributeShow = true
      }
    },
    editLogisticsNumber(){
      const logisticsLabel = this.labelDetails.filter(label => !!label.logisticsNumber)
      if(logisticsLabel.length === 0){
        this.$message.warning('请先完成分发')
      }else{
        this.manufacturers = this.unique(logisticsLabel.map(l => l.manufacturer))
        if(this.manufacturers.length === 1){
          this.distribution.manufacturerId = this.manufacturers[0].id
        }
        this.distributeShow = true
        this.edit = true
      }
    },

    getInDistributeInfo(){
      if(this.orderId){
        inDistributeInfoApi.getInDistributeInfo(this.orderId).then(resp =>{
          if(resp.data){
            var labels = resp.data.map(labelInfo=>{
              if(labelInfo.model.page > 1){
                labelInfo.barcodeNumber = labelInfo.labelCount * labelInfo.model.page
              }else{
                labelInfo.barcodeNumber = 0
              }
              return labelInfo
            })
            this.labelDetails = labels//.filter(item => item.labelState.name==='IN_EFFECT')

          }
        })
      }
    },
    /**
     * 数组去重
     */
    unique (arr) { // 根据唯一标识id来对数组进行过滤
      let res = new Map() // 定义常量 res,值为一个Map对象实例
      return arr.filter((arr) => !res.has(arr.id) && res.set(arr.id, 1))
    },
    uniqueStr (arr) { // 根据唯一标识id来对数组进行过滤
      let res = new Map() // 定义常量 res,值为一个Map对象实例
      return arr.filter((arr) => !res.has(arr) && res.set(arr, 1))
    },
    onCancelButtonClick(){
      this.distributeShow = false
      this.edit = false
      this.distribution = {
        loading: false,
        distributeRemark: null,
        manufacturerId:null,
        orderId: null,
        logisticsNumber: null,
        numbers:[
          {value:''}
        ],
      }
    },
    handleClose(){
      this.onCancelButtonClick()
    },
    onSubmitButtonClick(){
      this.$refs.distribution.validate().then(response => {
        this.$confirm('发货时同一厂家下所有标签都将一起发货，确认发货吗？', '提醒', {
          type: 'warning'
        }).then(() => {
            this.fullscreenLoading = true
            //处理物流单号
            this.distribution.logisticsNumber = Array.from(new Set(this.distribution.numbers.map(n => n.value))).join(',')
            this.distribution.orderId = this.id
            if(this.edit){
              inDistributeInfoApi.editLogisticsNumbers(this.distribution).then(response =>{
                if(response.data){
                  this.fullscreenLoading = false
                  this.$message.success("修改物流单号成功")
                  this.distributeShow = false
                  this.edit = false
                  this.distribution = {
                    loading: false,
                    distributeRemark: null,
                    manufacturerId:null,
                    orderId: null,
                    logisticsNumber: null,
                    numbers:[
                      {value:''}
                    ],
                  }
                  this.getInDistributeInfo()
                }
              }).catch(() =>{
                this.onCancelButtonClick()
                this.fullscreenLoading = false
              })
              
            }else{
              inDistributeInfoApi.batchDistribute(this.distribution).then(response=>{
                if(response.data){
                  this.fullscreenLoading = false
                  this.$message.success("分发成功")
                  this.distributeShow = false
                  this.distribution = {
                    loading: false,
                    distributeRemark: null,
                    manufacturerId:null,
                    orderId: null,
                    logisticsNumber: null,
                    numbers:[
                      {value:''}
                    ],
                  }
                  inDistributeInfoApi.getInDistributeInfo(this.orderId).then(resp =>{
                    if(resp.data){
                      var labels = resp.data.map(labelInfo=>{
                        if(labelInfo.model.page > 1){
                          labelInfo.barcodeNumber = labelInfo.labelCount * labelInfo.model.page
                        }else{
                          labelInfo.barcodeNumber = 0
                        }
                        return labelInfo
                      })
                      this.labelDetails = labels//.filter(item => item.labelState.name==='IN_EFFECT')
                      if(this.labelDetails[0].order.notDisManufacturerNum===0){
                        inDistributeInfoApi.updateDistributeDate(this.orderId).then(resp=>{})
                      }
                    }
                  })
                }
              }).catch(() =>{
                this.onCancelButtonClick()
                this.fullscreenLoading = false
              })
            }
          })
        })
      }
    }
}
</script>

<style lang="scss" scoped>

</style>