import api from '../api'
/**
 * 分发中详情
 */
export class inDistributeInfoApi extends api {
    getInDistributeInfo (id) {
        return this.request({
          url: `${this.servicePath}/${this.modulePath}/getInDistributeInfo/${id}`,
          method: 'get'
        })
    }
    updateDistributeDate(orderId){
      return this.request({
        url: `${this.servicePath}/${this.modulePath}/updateDistributeDate/${orderId}`,
        method: 'get'
      })
    }
    distribute (data){
        return this.request({
            url: `${this.servicePath}/${this.modulePath}/distribute`,
            method: 'post',
            data
        })
    }
    editLogisticsNumbers (data){
      return this.request({
          url: `${this.servicePath}/${this.modulePath}/editLogisticsNumbers`,
          method: 'post',
          data
        })
    }
    batchDistribute (data){
      return this.request({
          url: `${this.servicePath}/${this.modulePath}/batchDistribute`,
          method: 'post',
          data
        })
    }
}

export default new inDistributeInfoApi('inDistributeInfo')